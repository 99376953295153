"use client";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  chakra,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useRef } from "react";
import { FaMoon, FaSun } from "react-icons/fa";

import Logo, { LogoIcon } from "./logo";
import { MobileNavButton, MobileNavContent } from "./mobile-nav";
import { Search } from "./search";

function HeaderContent() {
  const mobileNav = useDisclosure();

  const { toggleColorMode: toggleMode } = useColorMode();

  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const mobileNavBtnRef = useRef<HTMLButtonElement | null>(null);

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus();
  }, [mobileNav.isOpen]);

  return (
    <>
      <Flex w="100%" h="100%" px="6" align="center" justify="space-between">
        <Flex align="center" pl="7">
          <chakra.a
            as={NextLink}
            href="/"
            display="block"
            aria-label="Rollout, Back to homepage"
          >
            <Logo display={{ base: "none", md: "block" }} height="1.5rem" />
            <Box minW="3rem" display={{ base: "block", md: "none" }}>
              <LogoIcon height="1.5rem" />
            </Box>
          </chakra.a>
        </Flex>

        <Flex
          justify="flex-end"
          w="100%"
          align="center"
          color="gray.400"
          maxW="1100px"
        >
          <chakra.div mr="3">
            <Search />
          </chakra.div>

          <HStack spacing="2">
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            <MobileNavButton
              ref={mobileNavBtnRef}
              aria-label="Open Menu"
              onClick={mobileNav.onOpen}
            />
          </HStack>
        </Flex>
      </Flex>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    </>
  );
}

function Header() {
  return (
    <>
      <chakra.header
        borderBottomWidth="1px"
        borderColor="#dee2e6"
        transition="border-color 0.2s, background-color 0.2s"
        pos="fixed"
        top="0"
        zIndex="3"
        bg="white"
        _dark={{ bg: "#101113", borderColor: "#2c2e33" }}
        left="0"
        right="0"
        width="full"
      >
        <chakra.div height="4.5rem" mx="auto" maxW="8xl">
          <HeaderContent />
        </chakra.div>
      </chakra.header>
    </>
  );
}

export default Header;
