import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  BoxProps,
  chakra,
  Collapse,
  IconButton,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { RefObject, useEffect, useRef } from "react";

import { SidebarLink } from "~/components/sidebar/sidebar-link";
import { RouteItem } from "~/utils/get-route-context";

type SidebarCategoryProps = BoxProps & {
  title: string;
  href?: string;
  isMobile?: boolean;
  contentRef?: RefObject<any>;
  routes?: RouteItem[];
};

export function SidebarCategory(props: SidebarCategoryProps) {
  const { isMobile, title, children, contentRef, href, routes, ...rest } =
    props;

  const pathname = usePathname();
  const ref = useRef<HTMLDivElement | null>(null);

  const getIsActive = () =>
    [...(routes ?? []).map((r) => r.path), href].some(
      (route) => route && pathname?.startsWith(route)
    );

  const collapse = useDisclosure({
    defaultIsOpen: getIsActive(),
  });

  useEffect(() => {
    const setCollapsed = getIsActive() ? collapse.onOpen : collapse.onClose;
    setCollapsed();
  }, [pathname]);

  return (
    <chakra.div ref={ref} marginTop="2" {...rest}>
      <SidebarLink
        href={href}
        pl={2}
        onClick={href == null ? collapse.onToggle : undefined}
        cursor="pointer"
      >
        <IconButton
          variant="unstyled"
          size="xs"
          minWidth="16px"
          aria-label={collapse.isOpen ? "Collapse" : "Expand"}
          onClick={collapse.onToggle}
        >
          {collapse.isOpen ? (
            <ChevronDownIcon fontSize={14} display="block" />
          ) : (
            <ChevronRightIcon fontSize={14} display="block" />
          )}
        </IconButton>

        {title}
      </SidebarLink>

      {routes != null && (
        <Collapse in={collapse.isOpen}>
          <Stack role="group" marginTop="2" marginLeft="5">
            {routes.map((route) => (
              <SidebarLink key={route.path} href={route.path!}>
                {route.title}
              </SidebarLink>
            ))}
          </Stack>
        </Collapse>
      )}
    </chakra.div>
  );
}
