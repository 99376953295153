"use client";

import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, chakra } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { useRef } from "react";

import { SidebarCategory } from "./sidebar-category";
import { SidebarLink } from "./sidebar-link";

import { RouteItem } from "~/utils/get-route-context";

export type SidebarContentProps = {
  routes: RouteItem[];
  contentRef?: any;
};

export function SidebarContent(props: SidebarContentProps) {
  const { routes, contentRef } = props;
  const pathname = usePathname();

  return (
    <>
      {routes.map((lvl1, idx) => {
        const isLvl1Active = lvl1.routes!.some((lvl1Route) =>
          pathname?.startsWith(lvl1Route.path!)
        );

        return (
          <chakra.div key={idx} marginBottom="6">
            {lvl1.heading && (
              <chakra.h4 fontSize="sm" fontWeight="bold" mb="1" pl={7} pr={2}>
                <chakra.a
                  href={lvl1.routes![0].path}
                  display="flex"
                  gap="2"
                  justifyContent="space-between"
                  alignItems="center"
                  _hover={{ color: "blue.500" }}
                >
                  {lvl1.title}{" "}
                  {isLvl1Active ? <ChevronDownIcon /> : <ChevronRightIcon />}
                </chakra.a>
              </chakra.h4>
            )}

            {isLvl1Active && (
              <chakra.div paddingTop="1">
                {lvl1.routes!.map((lvl2, index) => {
                  if (!lvl2.routes) {
                    return (
                      <SidebarLink mt="2" key={lvl2.path} href={lvl2.path!}>
                        {lvl2.title}
                      </SidebarLink>
                    );
                  }

                  return (
                    <SidebarCategory
                      key={lvl2.path! + index}
                      href={lvl2.path!}
                      contentRef={contentRef}
                      title={lvl2.title}
                      routes={lvl2.routes}
                    />
                  );
                })}
              </chakra.div>
            )}
          </chakra.div>
        );
      })}
    </>
  );
}

export type SidebarProps = {
  routes: RouteItem[];
};

export const Sidebar = ({ routes }: SidebarProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      aria-label="Main Navigation"
      as="nav"
      pos="sticky"
      overscrollBehavior="contain"
      top="73px"
      w="20rem"
      height="calc(100vh - 73px)"
      pl="6"
      pr="8"
      pt="6"
      pb="6"
      overflowY="auto"
      flexShrink={0}
      display={{ base: "none", md: "block" }}
      borderRightWidth="1px"
      borderColor="#dee2e6"
      _dark={{ borderColor: "#2c2e33" }}
    >
      <SidebarContent routes={routes} contentRef={ref} />
    </Box>
  );
};
