"use client";

import { Link } from "@chakra-ui/next-js";
import { PropsOf, chakra, useColorModeValue } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

export type SidebarLinkProps = Omit<PropsOf<typeof Link>, "href"> & {
  href?: string;
  icon?: React.ReactElement;
};

export const SidebarLink = ({ href, children, ...rest }: SidebarLinkProps) => {
  const pathname = usePathname();
  const isActive = href === pathname;
  const Component = href == null ? chakra.div : Link;
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isActive && ref.current != null) {
      scrollIntoView(ref.current, {
        scrollMode: "if-needed",
        boundary: (parent) => parent.tagName !== "BODY",
      });
    }
  }, []);

  return (
    <Component
      ref={(el: HTMLElement | null) => {
        ref.current = el;
      }}
      href={href!}
      display="flex"
      alignItems="center"
      lineHeight="tall"
      aria-current={isActive ? "page" : undefined}
      width="100%"
      pr="1"
      pl="7"
      py="1"
      rounded="md"
      fontSize="sm"
      color="fg"
      transition="all 0.2s"
      _activeLink={{
        bg: useColorModeValue("blue.50", "blue.900"),
        color: "accent-emphasis",
      }}
      _hover={{
        bg: useColorModeValue("blue.50", "blue.900"),
        color: "accent-emphasis",
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
