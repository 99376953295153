import { RouteItem } from "~/utils/get-route-context";

export const routes: RouteItem[] = [
  {
    title: "Overview",
    heading: true,
    routes: [
      {
        title: "Introduction to Rollout",
        path: "/overview/introduction-to-rollout",
      },
      {
        title: "What Does Rollout Provide?",
        path: "/overview/what-does-rollout-provide",
      },
      {
        title: "Getting Started",
        path: "/overview/getting-started",
      },
    ],
  },
  {
    title: "Quickstart",
    heading: true,
    routes: [
      {
        title: "Next.js",
        path: "/quickstart/nextjs",
      },
      {
        title: "Ruby on Rails",
        path: "/quickstart/ruby-on-rails",
      },
    ],
  },
  {
    title: "Documentation",
    heading: true,
    routes: [
      {
        title: "Fundamental Concepts",
        path: "/documentation/fundamental-concepts",
      },
      {
        title: "Rollout Project",
        path: "/documentation/rollout-project",
      },
      {
        title: "Local Development",
        path: "/documentation/local-development",
      },
      {
        title: "Building Connectors: Auth",
        path: "/documentation/building-connectors/auth",
        heading: true,
      },
      {
        title: "Building Connectors: Triggers",
        path: "/documentation/building-connectors/triggers",
        routes: [
          {
            title: "Push Triggers",
            path: "/documentation/building-connectors/triggers/push-triggers",
          },
          {
            title: "Polling Triggers",
            path: "/documentation/building-connectors/triggers/polling-triggers",
          },
          {
            title: "Webhook Triggers",
            path: "/documentation/building-connectors/triggers/webhook-triggers",
          },
        ],
      },
      {
        title: "Building Connectors: Actions",
        path: "/documentation/building-connectors/actions",
      },
      {
        title: "Building Connectors: Handling HTTP Requests",
        path: "/documentation/building-connectors/handling-http-requests",
      },
      {
        title: "Populating Dropdowns in Connector UI",
        path: "/documentation/building-connectors/populating-dropdowns",
      },
      {
        title: "Authenticating with Rollout",
        path: "/documentation/authenticating-with-rollout",
      },
      {
        title: "Automations",
        path: "/documentation/automations",
      },
      {
        title: "Automation Runs",
        path: "/documentation/automation-runs",
      },
      {
        title: "Blueprints",
        path: "/documentation/blueprints",
      },
      {
        title: "Credentials",
        path: "/documentation/credentials",
      },
      {
        title: "UI Components",
        path: "/documentation/ui-components",
        routes: [
          {
            title: "Installation",
            path: "/documentation/ui-components/installation",
          },
          {
            title: "Limiting Allowed Apps",
            path: "/documentation/ui-components/limiting-allowed-apps",
          },
          {
            title: "Prefilling Automation Form data",
            path: "/documentation/ui-components/prefilling-automation-form-data",
          },
          {
            title: "Customizing the Automation Form",
            path: "/documentation/ui-components/customizing-automation-form",
          },
          {
            title: "Customizing Input Fields",
            path: "/documentation/ui-components/customizing-input-fields",
          },
        ],
      },
      {
        title: "Whitelabeling Connectors",
        path: "/documentation/whitelabeling-connectors",
      },
    ],
  },
  {
    title: "Guides",
    heading: true,
    routes: [
      { title: "Migrating from Rollout v1", path: "/guides/migrating-from-v1" },
    ],
  },
  {
    title: "UI Components",
    heading: true,
    routes: [
      {
        title: "AutomationCreator",
        path: "/components/automation-creator/usage",
      },
      {
        title: "AutomationEditor",
        path: "/components/automation-editor/usage",
      },
      {
        title: "AutomationsManager",
        path: "/components/automations-manager/usage",
      },
      {
        title: "BlueprintEnabler",
        path: "/components/blueprint-enabler/usage",
      },
      {
        title: "BlueprintsManager",
        path: "/components/blueprints-manager/usage",
      },
      {
        title: "CredentialInput",
        path: "/components/credential-input/usage",
      },
      {
        title: "MyAutomations",
        path: "/components/my-automations/usage",
      },
      {
        title: "RolloutConnectProvider",
        path: "/components/rollout-connect-provider/usage",
      },
    ],
  },
  {
    title: "Framework API",
    heading: true,
    routes: [
      {
        title: "Introduction",
        path: "/framework-api/introduction",
      },
      {
        title: "Reference",
        path: "/framework-api/reference",
      },
    ],
  },
  {
    title: "Rest API",
    heading: true,
    routes: [
      {
        title: "Introduction",
        path: "/rest-api/introduction",
      },
      {
        title: "Reference",
        path: "/rest-api/reference",
      },
    ],
  },
  {
    title: "Connectors",
    heading: true,
    path: "/connectors",
    routes: [{ title: "Overview", path: "/connectors/overview" }],
  },
  {
    title: "User Guides",
    heading: true,
    routes: [
      {
        title: "Overview",
        path: "/user-guides/overview",
      },
      {
        title: "Hubspot",
        path: "/user-guides/hubspot",
      },
      {
        title: "Notion Markdown Cheat Sheet",
        path: "/user-guides/notion-markdown-cheat-sheet",
      },
      {
        title: "Slack",
        path: "/user-guides/slack",
      },
    ],
  },
  {
    title: "Support",
    heading: true,
    routes: [
      {
        title: "Where to contact us",
        path: "/support/where-to-contact-us",
      },
      {
        title: "Professional Services",
        path: "/support/professional-services",
      },
    ],
  },
  {
    title: "Security & Legal",
    heading: true,
    routes: [
      {
        title: "Security",
        path: "/security-and-legal/security",
      },
      {
        title: "GDPR",
        path: "/security-and-legal/gdpr",
      },
      {
        title: "Privacy Policy",
        path: "/security-and-legal/privacy-policy",
      },
      {
        title: "Terms of Service",
        path: "/security-and-legal/terms-of-service",
      },
      {
        title: "Data Processing Agreement",
        path: "/security-and-legal/data-processing-agreement",
      },
      {
        title: "Data Subprocessors",
        path: "/security-and-legal/data-subprocessors",
      },
      {
        title: "Google API Services Disclosure",
        path: "/security-and-legal/google-api-disclosure",
      },
      {
        title: "Contact Us",
        path: "/security-and-legal/contact-us",
      },
    ],
  },
];
