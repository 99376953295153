import { DocSearch } from "@docsearch/react";
import * as React from "react";
import "@docsearch/css";

import "./search.css";

export function Search() {
  return (
    <DocSearch
      appId="YXLBZ702AB"
      indexName="rollout"
      apiKey="7833426e84e092fd882a171faaf2d05d"
      insights
    />
  );
}
