"use client";

import { useUpdateEffect } from "@chakra-ui/hooks";
import { usePathname } from "next/navigation";

export const useRouteChanged = (fn: () => void) => {
  const pathname = usePathname();

  useUpdateEffect(() => {
    fn();
  }, [pathname]);
};
